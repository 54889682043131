//
// Jumbotron
// --------------------------------------------------

// NWF Custom:
.bg-black-trans-base {
  background-color:  @black-trans-base;
}

.bg-black-trans-darker {
  background-color:  @black-trans-darker;
}

.bg-black-trans {
  background-color:  @black-trans;
}

.bg-black-trans-lighter {
  background-color:  @black-trans-lighter;
}

@media (min-width: @screen-lg-min) {
  .container.padding-tb { 
    padding-top:15px; 
    padding-bottom:15px;
  }
}

.jumbotron {
  // padding-top:    @jumbotron-tb-padding;
  // padding-bottom: @jumbotron-tb-padding;
  // margin-bottom: @jumbotron-lr-padding;
  color: @jumbotron-color;
  background-color: @jumbotron-bg;

  // text-align: @text-center;

    h1,
    .h1 {
      color: @jumbotron-heading-color;
      font-family: @font-family-slab; 
      text-shadow: @carousel-text-shadow;
    }

    h4,
    .h4 {
      //NWF: targets jumbotron h4 -- 2nd line ...
      color: @jumbotron-heading-color;
      font-family: @font-family-condensed;
      font-weight: normal;
      text-shadow: @carousel-text-shadow;
    }

    p {
      margin-bottom: 15px;
      font-size: @jumbotron-font-size;
      font-weight: 400;
    }

    > hr {
      border-top-color: darken(@jumbotron-bg, 10%);
    }

    .container &,
    .container-fluid & {
      padding-left:  (@grid-gutter-width / 2);
      padding-right: (@grid-gutter-width / 2);
    }

    .container {
      max-width: 100%;
    }
    //NWF:
    @media screen and (min-width: @screen-sm-min) {
      padding-top:    (@jumbotron-t-padding);
      padding-bottom: (@jumbotron-b-padding);

      .container &,
      .container-fluid & {
        padding-left:  (@jumbotron-lr-padding * 2);
        padding-right: (@jumbotron-lr-padding * 2);
      }

      h1,
      .h1 {
        font-size: @jumbotron-heading-font-size;
        margin-top:0;
      }
    }
    
    @media screen and (max-width: @screen-lg-min) {
      h1 {
        font-size: (@jumbotron-heading-font-size * .85);
      }
    }
    @media screen and (max-width: @screen-md-min) {
      h1 {
        font-size: (@jumbotron-heading-font-size * .75);
      }
    }
    @media screen and (max-width: @screen-sm-min) {
      h1 {
        font-size: (@jumbotron-heading-font-size * .8);
      }
      //added by NWF:
      h4 { 
        font-size: (@font-size-h4 * .80);
      }
      p { 
        font-size: (@jumbotron-font-size * .9); 
      }
    }
}
